<template>

  <!-- Opp Source -->
  <iframe
    id="source"
    v-if="opp.source"
    :srcdoc="oppSource.source"
    width="100%"
    class="opp-source"
    :style="{ height: iframeHeight }"
  />

</template>

<script>

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('opps')

export default {
  name: 'OppSource',
  // -------------
  // Properties ==
  // -------------
  props: {
    mode: {
      type: String,
      default: 'details'
    }
  },
  // -------
  // Data ==
  // -------
  data() {
    return {
      iframe: null,
      iframeHeight: '300px'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'opp',
      'oppSource'
    ])
  },
  // ----------
  // Created ==
  // ----------
  created () {
    // Set up event listener
    window.addEventListener('message', this.handleMessage)
  },
  // --------
  // Watch ==
  // --------
  watch: {
    // Mode
    mode (value) {
      if (this.iframe && this.iframe.contentWindow) {
        // Send mode to iframe
        this.iframe.contentWindow.postMessage({ mode: value }, "*");
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Handlers
    handleMessage (message) {
      if (message.data.height) {
        // Update iframe height
        this.iframeHeight = `${message.data.height}px`
      } else if (message.data.update) {
        if (message.data.update.ready) {
          // Save iframe
          this.iframe = document.getElementById('source')
          // Send mode to iframe
          this.iframe.contentWindow.postMessage({ mode: this.mode }, "*");
        } else {
          // Send update event
          this.$emit('update', message.data.update)
        }
      }
    }
  }
}
</script>

<style lang="scss">

  .opp-source {
    border: none;
    width: 100%;
  }

</style>
