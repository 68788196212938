<template>

  <!-- View Opp Panel -->
  <div class="view-opp-panel">

    <!-- Router View -->
    <router-view />

    <!-- Opp Source -->
    <OppSource
      :mode="oppMode"
      @update="handleSourceUpdate"
    />

  </div>
</template>

<script>

// Components
import OppSource from '@/components/Opps/OppSource'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('opps')

// Helpers
const TurndownService = require('turndown').default

export default {
  name: 'ViewOppPanel',
  // -------------
  // Components ==
  // -------------
  components: {
    OppSource
  },
  // -------------
  // Properties ==
  // -------------
  props: {
    tag19: {
      type: String,
      default: null
    }
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.fetchOpp(vm.tag19)
      vm.setIndexVisibility({ to, from })
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.fetchOpp(to.params.tag19)
    this.setIndexVisibility({ to, from })
    next()
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: this.opp.title || '...'
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'newsDraft',
      'opp'
    ]),
    // UI
    oppMode () {
      if (this.$route.name === 'opps-edit') {
        return 'edit'
      } else if (this.$route.name === 'opps-news-draft') {
        return 'news'
      } else {
        return 'details'
      }
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'fetchOpp',
      'setIndexVisibility'
    ]),
    // Handlers
    handleSourceUpdate (data) {
      if (this.oppMode === 'edit') {
        this.opp.set(data)
      } else if (this.oppMode === 'news') {
        let update = {}
        if (data.description) {
          const turndownService = new TurndownService();
          update.body = turndownService.turndown(data.description)
        } else {
          update = data
        }
        this.newsDraft.set(update)
      }
    }
  }
}
</script>
